import { Button, Group, Text } from "@mantine/core";

interface ButtonProps {
  disabled: boolean,
  onClick: () => void | Promise<void>
  text: string
}

interface Props {
  createButtonProps: ButtonProps,
  exportButtonProps: ButtonProps,
  total: number | undefined
}

function Toolbar({ total, createButtonProps, exportButtonProps }: Props) {
  return (
    <Group align="center" spacing="xs">
      <Group align="center" spacing="xs" mr="auto">
        <Button
          onClick={createButtonProps.onClick}
          disabled={createButtonProps.disabled}
          data-cy="activation-request-button"
        >
          {createButtonProps.text}
        </Button>
        <Button
          onClick={exportButtonProps.onClick}
          disabled={exportButtonProps.disabled}
          data-cy="number-export-button"
          color="teal"
        >
          {exportButtonProps.text}
        </Button>
      </Group>
      <Text size="sm" color="gray">{(total === undefined) ? '' : `Найдено номеров: ${total}`}</Text>
    </Group>
  );
}

export default Toolbar;
