/* tslint:disable */
/* eslint-disable */
/**
 * Номера.Биз
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  GenericError,
  NumberList200Response,
} from '../models';
import {
    GenericErrorFromJSON,
    GenericErrorToJSON,
    NumberList200ResponseFromJSON,
    NumberList200ResponseToJSON,
} from '../models';

export interface NumberExportRequest {
    landline?: string;
    rentPriceGte?: string;
    rentPriceLte?: string;
    mask?: string;
    maskStart?: string;
    maskEnd?: string;
    providerIdIn?: string;
    regionIdIn?: string;
    tagsIdIn?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

export interface NumberListRequest {
    landline?: string;
    rentPriceGte?: string;
    rentPriceLte?: string;
    mask?: string;
    maskStart?: string;
    maskEnd?: string;
    providerIdIn?: string;
    regionIdIn?: string;
    tagsIdIn?: string;
    ordering?: string;
    limit?: number;
    offset?: number;
}

/**
 *
 */
export class NumberApi extends runtime.BaseAPI {

    /**
     */
    async numberExportRaw(requestParameters: NumberExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.landline !== undefined) {
            queryParameters['landline'] = requestParameters.landline;
        }

        if (requestParameters.rentPriceGte !== undefined) {
            queryParameters['rent_price__gte'] = requestParameters.rentPriceGte;
        }

        if (requestParameters.rentPriceLte !== undefined) {
            queryParameters['rent_price__lte'] = requestParameters.rentPriceLte;
        }

        if (requestParameters.mask !== undefined) {
            queryParameters['mask'] = requestParameters.mask;
        }

        if (requestParameters.maskStart !== undefined) {
            queryParameters['mask_start'] = requestParameters.maskStart;
        }

        if (requestParameters.maskEnd !== undefined) {
            queryParameters['mask_end'] = requestParameters.maskEnd;
        }

        if (requestParameters.providerIdIn !== undefined) {
            queryParameters['provider__id__in'] = requestParameters.providerIdIn;
        }

        if (requestParameters.regionIdIn !== undefined) {
            queryParameters['region__id__in'] = requestParameters.regionIdIn;
        }

        if (requestParameters.tagsIdIn !== undefined) {
            queryParameters['tags__id__in'] = requestParameters.tagsIdIn;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/number/export/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     */
    async numberExport(requestParameters: NumberExportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.numberExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async numberListRaw(requestParameters: NumberListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<NumberList200Response>> {
        const queryParameters: any = {};

        if (requestParameters.landline !== undefined) {
            queryParameters['landline'] = requestParameters.landline;
        }

        if (requestParameters.rentPriceGte !== undefined) {
            queryParameters['rent_price__gte'] = requestParameters.rentPriceGte;
        }

        if (requestParameters.rentPriceLte !== undefined) {
            queryParameters['rent_price__lte'] = requestParameters.rentPriceLte;
        }

        if (requestParameters.mask !== undefined) {
            queryParameters['mask'] = requestParameters.mask;
        }

        if (requestParameters.maskStart !== undefined) {
            queryParameters['mask_start'] = requestParameters.maskStart;
        }

        if (requestParameters.maskEnd !== undefined) {
            queryParameters['mask_end'] = requestParameters.maskEnd;
        }

        if (requestParameters.providerIdIn !== undefined) {
            queryParameters['provider__id__in'] = requestParameters.providerIdIn;
        }

        if (requestParameters.regionIdIn !== undefined) {
            queryParameters['region__id__in'] = requestParameters.regionIdIn;
        }

        if (requestParameters.tagsIdIn !== undefined) {
            queryParameters['tags__id__in'] = requestParameters.tagsIdIn;
        }

        if (requestParameters.ordering !== undefined) {
            queryParameters['ordering'] = requestParameters.ordering;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/number/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NumberList200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async numberList(requestParameters: NumberListRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<NumberList200Response> {
        const response = await this.numberListRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
