import { useCallback, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { Box, LoadingOverlay, Stack } from "@mantine/core";
import sortBy from "lodash/sortBy";

import Header from "./components/Header";
import Filters from "./components/Filters";
import Toolbar from "./components/Toolbar";
import NumberGrid from "./components/NumberGrid";
import ActivationRequestModal from "./components/ActivationRequestModal";

import { useApi, useApiRequest, useStrictEffect } from "./hooks";

import type { FilterValues } from "./types/app";
import type { NumberListRequest, Number, Tag, Provider, Region, NumberExportRequest } from "./client";

const NUMBERS_PER_PAGE = 50;
const LOAD_MORE_OFFSET = 100;

function filterToRequest(filterValues: FilterValues): NumberListRequest {
  let landline: string | undefined;
  if (filterValues.kind === "landline") {
    landline = "true";
  } else if (filterValues.kind === "federal") {
    landline = "false";
  } else {
    landline = undefined;
  }

  return {
    mask: filterValues.mask || undefined,
    maskStart: filterValues.maskStart ? "true" : undefined,
    maskEnd: filterValues.maskEnd ? "true" : undefined,
    landline,
    rentPriceGte: filterValues.rentPriceFrom?.toString(),
    rentPriceLte: filterValues.rentPriceTo?.toString(),
    providerIdIn: filterValues.providerIds.join(",") || undefined,
    regionIdIn: filterValues.regionIds.join(",") || undefined,
    tagsIdIn: filterValues.tagIds.join(",") || undefined,
  }
}

function sortToRequest(sortAsc: boolean): NumberListRequest {
  return {
    ordering: sortAsc ? "rent_price" : "-rent_price",
  };
}

function App() {
  const api = useApi();

  const [tags, setTags] = useState<Tag[]>([]);
  const [loadTags, loadTagsState] = useApiRequest(async () => {
    let results = await api.tag.tagList();
    results = sortBy(results, ["name"]);
    setTags(results);
  });

  const [providers, setProviders] = useState<Provider[]>([]);
  const [loadProviders, loadProvidersState] = useApiRequest(async () => {
    let results = await api.provider.providerList();
    results = sortBy(results, ["displayName"]);
    setProviders(results);
  });

  const [regions, setRegions] = useState<Region[]>([]);
  const [loadRegions, loadRegionsState] = useApiRequest(async () => {
    let results = await api.region.regionList();
    results = sortBy(results, ["displayName"]);
    setRegions(results);
  });

  const [numberPages, setNumberPages] = useState<Number[][]>([]);
  const numbers = numberPages.reduce((arr, page) => arr.concat(page), []);
  const [total, setTotal] = useState<number | undefined>(undefined);
  const [filterValues, setFilterValues] = useState<FilterValues>({
    mask: "",
    maskStart: false,
    maskEnd: false,
    kind: undefined,
    rentPriceFrom: undefined,
    rentPriceTo: undefined,
    providerIds: [],
    tagIds: [],
    regionIds: [],
  });
  const [sortAsc, setSortAsc] = useState(false);

  const [loadNumbers, loadNumbersState] = useApiRequest(async (request: NumberListRequest) => {
    return await api.number.numberList(request);
  });

  const [generateFile, generateFileState] = useApiRequest(async (request: NumberExportRequest) => {
    return await api.number.numberExportRaw(request);
  })

  const isFilterDataLoading = (
    loadTagsState === "loading"
    || loadProvidersState === "loading"
    || loadRegionsState === "loading"
    || generateFileState === "loading"
  );

  const onGenerateFileClick = async function () {
    const resp = await generateFile({
      ...filterToRequest(filterValues),
      ...sortToRequest(sortAsc),
    });

    if (!resp) return;

    const disposition = resp.raw.headers.get('Content-Disposition');

    let matches;
    if (disposition && disposition.includes('attachment')) {
      const filenameRegex = /filename="(.*?\.xlsx)"/;
      matches = filenameRegex.exec(disposition);

    }
    const filename = matches?.[1] ? decodeURIComponent(matches[1]) : 'Выгрузка номеров.xlsx';

    const blob = await resp.value();
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(link.href);
  }

  const reloadNumbers = useCallback(async () => {
    if (loadNumbersState === "loading") {
      return;
    }

    let response = await loadNumbers({
      ...filterToRequest(filterValues),
      ...sortToRequest(sortAsc),
      offset: 0,
      limit: NUMBERS_PER_PAGE,
    });

    if (response) {
      setNumberPages([response.results]);
      setTotal(response.count);
      setSelectedNumber(null);
    }
  }, [filterValues, loadNumbers, loadNumbersState, sortAsc]);

  const loadMoreNumbers = useCallback(async () => {
    if (loadNumbersState === "loading") {
      return;
    }

    let response = await loadNumbers({
      ...filterToRequest(filterValues),
      ...sortToRequest(sortAsc),
      offset: numbers.length,
      limit: NUMBERS_PER_PAGE,
    });

    if (response) {
      setNumberPages([...numberPages, response.results]);
      setTotal(response.count);
    }
  }, [filterValues, loadNumbers, loadNumbersState, numberPages, numbers.length, sortAsc]);

  const onFiltersChange = useCallback(async (filterValues: FilterValues) => {
    setFilterValues(filterValues);

    if (loadNumbersState === "loading") {
      return;
    }

    setNumberPages([]);
    setTotal(undefined);

    let response = await loadNumbers({
      ...filterToRequest(filterValues),
      ...sortToRequest(sortAsc),
      offset: 0,
      limit: NUMBERS_PER_PAGE,
    });

    if (response) {
      setNumberPages([response.results]);
      setTotal(response.count);
      setSelectedNumber(null);
    }
  }, [loadNumbers, loadNumbersState, sortAsc]);

  const onSortChange = useCallback(async () => {
    setSortAsc(!sortAsc);

    if (loadNumbersState === "loading") {
      return;
    }

    setNumberPages([]);
    setTotal(undefined);

    let response = await loadNumbers({
      ...filterToRequest(filterValues),
      ...sortToRequest(!sortAsc),
      offset: 0,
      limit: NUMBERS_PER_PAGE,
    });

    if (response) {
      setNumberPages([response.results]);
      setTotal(response.count);
      setSelectedNumber(null);
    }
  }, [filterValues, loadNumbers, loadNumbersState, sortAsc]);

  useStrictEffect(() => {
    loadTags();
    loadProviders();
    loadRegions();
    reloadNumbers();
  }, []);

  const [selectedNumber, setSelectedNumber] = useState<Number | null>(null);
  const [activationRequestModalOpened, { open: openActivationRequestModal, close: closeActivationRequestModal }] = useDisclosure(false);

  return (
    <Stack spacing={0} style={{ minHeight: "100vh" }}>
      <Stack sx={(theme) => ({
        padding: theme.spacing.md,
        borderBottom: `1px solid ${theme.colors.gray[3]}`,
      })}>
        <Header />
        <Box pos="relative">
          <LoadingOverlay visible={isFilterDataLoading} overlayBlur={2} />
          <Filters
            providers={providers}
            tags={tags}
            regions={regions}
            values={filterValues}
            onChange={onFiltersChange}
          />
        </Box>
        <Toolbar
          createButtonProps={{
            disabled: selectedNumber === null,
            onClick: openActivationRequestModal,
            text: selectedNumber !== null ? 'Отправить заявку' : 'Выберите номер'
          }}
          exportButtonProps={{
            disabled: generateFileState === "loading" || !total,
            onClick: onGenerateFileClick,
            text: generateFileState === "loading"
              ? 'Генерация...'
              : total
                ? 'Экспортировать номера'
                : 'Список пуст'
          }}
          total={total}
        />
      </Stack>
      <Box pos="relative" style={{ flexGrow: "1" }}>
        <LoadingOverlay visible={numbers.length === 0 && loadNumbersState === "loading"} overlayBlur={2} />
        <NumberGrid
          numberPages={numberPages}
          hasMore={total !== undefined && total > numbers.length}
          loadMoreOffest={LOAD_MORE_OFFSET}
          onLoadMore={loadMoreNumbers}
          sortAsc={sortAsc}
          onChangeSort={onSortChange}
          selectedNumberId={selectedNumber ? (selectedNumber.id || 0) : null}
          onSelectNumber={setSelectedNumber}
        />
      </Box>

      {selectedNumber &&
        <ActivationRequestModal
          number={selectedNumber}
          opened={activationRequestModalOpened}
          onClose={closeActivationRequestModal}
        />
      }
    </Stack>
  );
}

export default App;
